import React from "react";
import Slider from "react-slick";

const teams = [
  {
    id: 1,
    img: "/images/coach-1.png",
    name: "Coach David Trần Xuân Hoà",
    position: "Business COACH",
  },
  {
    id: 2,
    img: "/images/coach-2.png",
    name: "Coach Emmy Lê Thị Hải",
    position: "Business COACH",
  },
  {
    id: 5,
    img: "/images/coach-5.png",
    name: "Coach Kaka Lê Ngọc Đăng",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-6.png",
    name: "Coach Leo Võ Thái Lâm",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-7.png",
    name: "Coach Martin Nguyễn Hải Đăng",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-8.png",
    name: "Coach NeO Nguyễn Đình Bảo",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-9.png",
    name: "Coach Rita Tô Quý Ngọc Châu",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-10.png",
    name: "Coach Tamy Lê Thị Ngọc Thảo",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-11.png",
    name: "Coach TheO Vũ Bá Thế",
    position: "Business COACH",
  },
  {
    id: 6,
    img: "/images/coach-12.png",
    name: "Coach Tony Thái Sơn",
    position: "Business COACH",
  },
];

const Team = () => {
  return (
    <section className="team md:py-section py-sectionMB bg-[url('../public/images/team-bg.png')] bg-cover bg-center bg-no-repeat">
      <div className="mx-[15px] md:ml-[50px] grid grid-cols-1 md:grid-cols-3">
        <h2
          data-aos="fade-right"
          data-aos-duration="1000"
          className="font-semibold text-[24px] md:text-[38px] leading-[1.2] text-white col-span-1"
        >
          ĐỘI NGŨ NHÀ <br />{" "}
          <span className="text-[40px] md:text-[64px]">HUẤN LUYỆN</span> <br />{" "}
          DOANH NGHIỆP CỦA <br />{" "}
          <span className="text-[40px] md:text-[64px]">ACTIONCOACH</span> <br />{" "}
          <span className="text-[40px] md:text-[64px]">VIỆT NAM</span>
        </h2>
        <TeamSlide></TeamSlide>
      </div>
    </section>
  );
};

const TeamSlide = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <TeamNext />,
    prevArrow: <TeamPrev />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      className="col-span-2 team-slide md:ml-[100px] mt-[40px] md:mt-[0px]"
    >
      <Slider {...settings}>
        {teams.map((item) => (
          <TeamItem key={item.id} item={item}></TeamItem>
        ))}
      </Slider>
    </div>
  );
};

const TeamItem = ({ item: { img, name, position } }) => {
  return (
    <div className="relative h-[352px] mr-[15px]">
      <img
        className="h-[100%] w-[100%] object-cover rounded-tl-[20px] rounded-tr-[20px]"
        src={img}
        alt=""
      />
      <div className="py-[20px] px-[8px] absolute bottom-[60px] left-0 bg-gradient-to-br to-[#00558F] from-[#CD163F] right-0 text-white text-center rounded-[20px]">
        <p className="font-semibold text-[12px]">{name}</p>
        {/* <p className="text-[14px] font-semibold">{position}</p> */}
      </div>
    </div>
  );
};

function TeamNext(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`team-next ${className}`}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    />
  );
}

function TeamPrev(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`team-prev ${className}`}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    />
  );
}

export default Team;
